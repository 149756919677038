const form = document.querySelector('#nslSubscription');
const btnSubmit = document.querySelector('#nslSubscription-send');
const loader = document.querySelector('.nslWaitloader');
const result_block = document.querySelector('.response');

export default class NslSubscription {    

    static validate = (function() {

        form.addEventListener('submit', function (event) {
            event.preventDefault()
            event.stopPropagation()
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
                form.classList.add('was-validated')
                let list = form.querySelectorAll(':invalid');
                for (var item of list) {
                    if(item.id == "nsl-privacy"){
                        item.classList.add('is-invalid')
                    }
                }
            }else{
              const formData = new FormData(form);  
            }

                //
                //const formData = new FormData(form);
            
        }, false)

        form.addEventListener("formdata", (e) => {
            loader.style.display = 'block';
            const wait = document.querySelector('.wait');
            wait.style.display = 'flex';

            // modifies the form data
            const formData = e.formData;
            const gtmNewsletterSubscription = form.dataset.gtmEventNewsletterSubscription;

            //se utilizzeremo anche il nome e cognome
            //+ '&ocsvFldNames=campo1&csvFldValues='+ encodeURIComponent( formData.get("campo1") )

            let dati = 'list='+ encodeURIComponent( formData.get("list") )+ '&group=' + encodeURIComponent( formData.get("group") ) +  '&email=' + encodeURIComponent( formData.get("email") ) + '&ocsvFldNames=campo1&csvFldValues='+ encodeURIComponent( formData.get("name") )  + '&confirm=true'  ;
            //console.log(dati);
           
           fetch('https://a1e0h.mailupclient.com/frontend/subscribe.aspx', {
                method: 'POST',
                body: dati,
                headers: {
                'Content-type': 'application/x-www-form-urlencoded'
                }
            }).then(function (response) {
                if (response.ok) {
                    return response;
                }else{
                    return Promise.reject(response);
                }                
            }).then(function (data) {
                //console.log(data);
                console.log(gtmNewsletterSubscription);
                if(data.status === 200){
                    //datalayer event GTM
                    if (typeof gtmNewsletterSubscription !== 'undefined') {
                        let event = { event: gtmNewsletterSubscription };
                        console.log(event);
                        try {
                            event = JSON.parse(gtmNewsletterSubscription);
                        } catch (error) { 
                        }// eslint-disable no-empty  
                        dataLayer.push(event);
                    }
                    loader.style.display='none';
                    result_block.style.display='block';
                    setTimeout(function(){ 
                        result_block.style.display='none';
                        wait.style.display = 'none';
                    }, 2000);
                }

            }).catch(function (error) {
                console.warn('Something went wrong.', error);
            });            

         });    

    }); 

    static init(){
       NslSubscription.validate();
    }    
    
}