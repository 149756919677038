const hamburger = document.querySelector('.menu');
const menu_fullpage = document.querySelector('.menu-fullpage');
const menu_fullpage_wrapper = document.querySelector('.menu-fullpage-wrapper');

export default class MenuToggler {    

    static toggleMenu = (function() {
        hamburger.classList.contains('active') ? hamburger.classList.remove('active') : hamburger.classList.add('active') ;
        menu_fullpage.classList.contains('open') ? (menu_fullpage.classList.remove('open'), menu_fullpage_wrapper.classList.remove('open'),document.documentElement.style.overflow = "auto" ) :
                                                   (menu_fullpage.classList.add('open'),  menu_fullpage_wrapper.classList.add('open'),document.documentElement.style.overflow = "hidden" );
    });    

    static init(){
        hamburger.addEventListener('click', this.toggleMenu, false);
    }    
    
}