
// Import gsap
import { gsap } from "gsap";

export default class AnimationHome {    

    static animateHeader = (function() {
       // ANIMATE WINTER 
       const h = document.querySelector('.headerHome').offsetTop;
        const tl = gsap.timeline({
            scrollTrigger: {
            trigger: '.headerHome',
            pin: true,//'.container-main',
            scrub: 0.5,
            start: "-"+h+"px top",
            end: "bottom center",//"+=100%",
            /*markers: {
                startColor: "black",
                endColor: "fuchsia"
            },*/
            }
        });
        tl.add('start')
        tl.to('.headerHome h1', {
            ease: "none",
            opacity: 0,
            stagger: 1,
            duration:3,
        }, 'start');
        tl.to('.headerHome-img:nth-of-type(1)', {//Immagine sinistra
            ease: "none",
            //y: '-300%',//y: '-300%',
            bottom:'100%',
            stagger: 1,
            duration:4,//3
        }, 'start');
        tl.to('.headerHome-img:nth-of-type(2)', {//Immagine centrale
            ease: "none",
            top: '100%',//y: '200%',
            stagger: 1,
            duration:4,//3
        }, 'start');
        tl.to('.headerHome-img:nth-of-type(3)', {//Immagine destra
            ease: "none",
            //y: '-200%',//y: '-200%',
            bottom:'100%',
            stagger: 1,
            duration:4,//3
        }, 'start');
    });    

    static animateClaimHome = (function() {
        
        // ANIMATE TEXT ENTRANCE HP [FUNZIONA] 
        //text entrance FIRST TYPE WORKING
        /*const tl2 = gsap.timeline({
            scrollTrigger: {
            trigger: '.claimHome',
            pin: '.claimHome',//'.container-main',
            scrub: 0.5,
            start: "top 100px",
            //start: "center center",//"-=70%",
            end:  "+=70%",// indica quando finisce lo scroll e passa alla sezione successiva
            markers: {
                startColor: "green",
                endColor: "red"
            },
            }
        });
        tl2.add('txt')
        tl2.fromTo(".claimHome .from-right", { x: '120%' }, { x: 0, duration: 1 }, 'txt');
        tl2.fromTo(".claimHome .from-left", { x: '-120%' }, { x: '0%', duration: 1 }, 'txt');
        tl2.to(".claimHome img", { x:'-60%',y:-100,scale:2.68,  duration: 1});
        */

        
        //ATTUALE FUNZIONANTE
        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: '.claimHome',
                //pin: '.claimHome',//'.container-main',
                scrub: 0.5,
                start: "top bottom",
                end:  "bottom bottom",
                //start: "center center",//"-=70%",
                //end:  "+=20%",// indica quando finisce lo scroll e passa alla sezione successiva
                //markers: {
                //    startColor: "blue",
                //    endColor: "yellow"
                //},
            }
        });
        tl2.add('txt')
        tl2.fromTo(".claimHome .from-right", { x: '120%' }, { x: 0, duration: 1 }, 'txt');
        tl2.fromTo(".claimHome .from-left", { x: '-120%' }, { x: '0%', duration: 1 }, 'txt');
        tl2.to(".claimHome .claimImg", {left: '55%', duration: 1 }, 'txt');
        /*ATTUALE FUNZIONANTE
        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: '.claimHome img',
                pin: '.claimHome',//'.container-main',
                scrub: 0.5,
                start:"-50% 110px", //"-40% 110px",
                //start: "center center",//"-=70%",
                end:  "+=70%",// indica quando finisce lo scroll e passa alla sezione successiva
            }
        });
        // OLD
        //tl4.to(".claimHome img", { x:'-60%',y:-100,scale:2.68,  duration: 1}); 
        //TEST Ingrandimento immagine con immagine dentro h2
        let offsetParent = document.querySelector('.claimHome').getBoundingClientRect(),
            offsetH2 = document.querySelector('.claimHome .hasImg').getBoundingClientRect(),
            offsetEl = document.querySelector('.claimHome img').getBoundingClientRect(),
            imgW = offsetH2.width;
            //imgX = - offsetEl.x ;
            //calcolo spostamento
            //console.log('distanzax da immagine a bordo ' + offsetEl.x);
            //console.log('distanzax da h2 a bordo ' + offsetH2.x);
            //console.log('spostamento a -x' + (offsetEl.x - offsetH2.x));
            let imgX = offsetEl.x - offsetH2.x 
        tl4.to(".claimHome img", { x:- imgX+'px',y:0, width: imgW+"px",  duration: 1}); //height: imgH+'px',
        */

        //TEST CON IMG ABSOLUTE
        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: '.claimHome',
                pin: '.claimHome',//'.container-main',
                scrub: 0.5,
                start:"top 110px", //"-40% 110px",   "-70% 110px"
                //start: "center center",//"-=70%",
                end:  "+=70%",// indica quando finisce lo scroll e passa alla sezione successiva
                /*markers: {
                    startColor: "black",
                    endColor: "black"
                },*/
            }
        });
        let offsetParent = document.querySelector('.claimHome').getBoundingClientRect(),
            offsetH2 = document.querySelector('.claimHome .hasImg').getBoundingClientRect(),
            offsetEl = document.querySelector('.claimHome img').getBoundingClientRect(),
            imgW = offsetH2.width;
            let imgX = offsetEl.x - offsetH2.x 
        tl4.to(".claimHome .claimImg", { /*x:0,/*y:0,*/left:'4.5%', width: imgW+"px",  duration: 1}); //height: imgH+'px',
        //FINE TEST CON IMG ABSOLUTE    

    });

    static animateAboutUs = (function() {
        // ANIMATE ABOUT US HP
        const tl3 = gsap.timeline({
            scrollTrigger: {
            trigger: '.aboutHome',
            start: "top center",
            end:  "+=70%",// indica quando finisce lo scroll e passa alla sezione successiva
            //markers: true
            }
        });
        tl3.add('animateAbout')
        tl3.fromTo(".aboutHome h2", { opacity:0, y:-50 }, { opacity: 1, y:0, duration: 1 }, 'animateAbout');
        tl3.fromTo(".aboutHome .aboutHome-description", { opacity:0, y:-50 }, { opacity: 1, y:0, duration: .8 }, 'animateAbout');
    });

    //ANIMAZIONE LISTA EVENTI HOME PAGE
    static animateEventList = (function() {
        // Panels
        let events = gsap.utils.toArray(".panel");

        //console.log(events.length);
        if(events.length > 2){
            //calculate x axis px movement
            let blocks = events.length,
                gap = 100,
                blockWidth = document.querySelector(".panel").clientWidth + gap,
                totalBlocksWidth = blocks * blockWidth,
                containerWidth = document.querySelector("#wrapper-home").clientWidth,//1600
                winwodW = document.body.clientWidth,
                minus = (winwodW - containerWidth) / 2,
                xAxis = totalBlocksWidth - containerWidth - minus;

            //HORIZZONTAL SCROLL        
            const eventsScroll = gsap.timeline({
            scrollTrigger: {
                trigger: ".eventsHome",
                pin: true,
                scrub: 1,
                start: 'top 100px',
                //snap: 1 / (sections.length - 1),
                // base vertical scrolling on how wide the container is so it feels more natural.
                end: "+=120%",
                /*markers: {
                    startColor: "black",
                    endColor: "black"
                },*/
            }
            });
            eventsScroll.to(events, {
                x:- + xAxis,//se tre
                ease: "none",  
            });
        }
    });

    static init(){
        let media = gsap.matchMedia();
        media.add("(min-width: 981px)", () => {
            AnimationHome.animateHeader();            
            AnimationHome.animateClaimHome();
            AnimationHome.animateAboutUs();
            AnimationHome.animateEventList();
          
        })        
        
    }    
    
}