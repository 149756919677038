// import GLightbox
import GLightbox from 'glightbox';
const eventView = document.querySelector('.events-view');

export default class LightBox {  

    static eventViewLightbox(){
        const lightbox = GLightbox();
        lightbox.on('open', () => {
            console.log('glightbox?');
        });
    }

    static init(){
        console.log('glightbox');
        this.eventViewLightbox();
    } 
}