
// Import gsap
import { gsap } from "gsap";

export default class AnimationCollection {    

    static animateImages = (function() {
        /* Images */
        let images = gsap.utils.toArray(".animateDiv");
        images.forEach((el, i) => {
            gsap.to(el, { 
                x:()=> {return innerWidth > 980 ? '-=20vw' : '-=5vw' }, //'-=20vw',
                width:()=> {return innerWidth > 980 ? '+=40vw' : '+=10vw' }, //'+=40vw',
                //scaleX:()=> {return innerWidth > 980 ? '1' : '1' }, 
                transformOrigin:'center',
                duration: 1,
                scrollTrigger: {
                    trigger: el,
                    //pin: '.collection-image',//'.container-main',
                    scrub: 0.5,
                    //start: "top 100px",
                    start: ()=> {return innerWidth > 980 ? "+=20% center" : "0 +=30%"}, //"+=20% center",
                    //start: "center center",//"-=70%",
                    end:  ()=> {return innerWidth > 980 ? "+=70%" : "center +=30%"}, //"+=70%",// indica quando finisce lo scroll e passa alla sezione successiva
                    /*markers: {
                        startColor: "green",
                        endColor: "red"
                    },*/
                }
            });
        })

    });    

    static init(){
        // create
        //let mm = gsap.matchMedia();
        // add a media query. When it matches, the associated function will run
        //mm.add("(min-width: 1366px)", () => {
            AnimationCollection.animateImages();
        //})
        
    }    
    
}

