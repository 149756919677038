
// Import gsap
import { gsap } from "gsap";

export default class AnimationAboutPage {    

   static animateImages = (function() {
        /* Images */
        let images = gsap.utils.toArray(".animateDiv");
        images.forEach((el, i) => {            
            const container = el.parentNode;
            console.log(container);
            gsap.to(el, { 
                //scale: 1.4, ->10/10/2024
                x:()=> {return innerWidth > 980 ? '-=20vw' : '-=5vw' }, //'-=20vw',
                width:()=> {return innerWidth > 980 ? '+=40vw' : '+=10vw' }, //'+=40vw',                 
                transformOrigin:'center',
                ease: "none",
                duration: .2,
                scrollTrigger: {
                    trigger:el,//'container',// el,
                    scrub: 0.5,
                    //start: "+=0% 80%",// "+=40% 80%", //center
                    //end:   "100% 80%", //center      
                    start: ()=> {return innerWidth > 980 ? "+=20% center" : "0 +=30%"}, 
                    end:  ()=> {return innerWidth > 980 ? "+=70%" : "center +=30%"},// indica quando finisce lo scroll e passa alla sezione successiva
                   /* markers: {
                        startColor: "green",
                        endColor: "red"
                    },*/
                }
            });
        })

    }); 

    static init(){
        // create
        //let mm = gsap.matchMedia();
        // add a media query. When it matches, the associated function will run
        //mm.add("(min-width: 981px)", () => {
            AnimationAboutPage.animateImages();
        //})
    }    
    
}

