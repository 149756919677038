
let buttons = document.querySelectorAll('.eventFilter');
const events = document.querySelectorAll(`[data-filter]`);

export default class eventFilter {    

    static filter(){
        buttons.forEach(button => {
           	button.addEventListener('click', (el) => {
				el.preventDefault()
				eventFilter.removeActive();
				button.classList.contains('active') ? '' : button.classList.add('active')                
                //get witch button is fired
                let target = el.target;
                //get what filter i have to use
                let filterType = target.dataset.type;
				eventFilter.showAll();
                events.forEach(event => {
					if(event.dataset.filter === filterType ){
						event.style.display === 'block'
					}else if(filterType !== "0"){
						event.style.display = 'none'
					}
                });
            });
		});
    }   // end filter

	static showAll(){
		events.forEach(event => {
            event.style.display = 'block'
        });
	}

	static removeActive(){
		buttons.forEach(button => {
            button.classList.remove('active')
        });
	}

	static init(){
		eventFilter.filter();
	}
    		
}